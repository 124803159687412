header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: none;
    z-index: 20;
    nav {
        .container {
            padding: 0 16px;
            font-weight: 500;
            margin: 0;
            max-width: 100%;
            .header__nav {
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                transform: translateY(15%);
                img {
                    margin-right: 10px;
                }
            }

            #basic-navbar-nav {
                position: relative;
                .nav-link {
                    cursor: context-menu;
                }
                .button__login,
                .button__signup {
                    border-radius: 4px;
                    padding: 0 16px;
                    border: none;
                    min-width: 80px;
                    box-shadow: none;
                    color: #fff;
                    font-weight: 500;
                    height: 32px;
                }
                .button__login {
                    color: rgb(249, 249, 249);
                    border: 1px solid rgb(255, 255, 255);
                    background: transparent;
                }
                .button__signup {
                    background: rgb(141, 101, 234);
                }

                & a {
                    color: #fff;
                    text-decoration: none;
                }
                .nav__item {
                    position: relative;
                    transition: all 0.5s;
                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: -20px;
                        height: 5px;
                        width: 0;
                        opacity: 0;
                        background: var(--text-hover);
                        transition: all 0.5s;
                    }
                    &:hover {
                        color: var(--text-hover);
                        &::after {
                            opacity: 1;
                            width: 100%;
                        }
                        // border-bottom: 5px solid var(--text-hover);
                    }
                    &--active {
                        color: var(--text-hover);
                        position: relative;
                        &::after {
                            content: "";
                            position: absolute;
                            left: 0;
                            bottom: -20px;
                            height: 5px;
                            width: 100%;
                            opacity: 1;
                            background: var(--text-hover);
                            transition: all 0.5s;
                        }
                    }
                }
                .user__mobile {
                    background: transparent;
                    border: none;
                    font-weight: 500;
                    display: none;
                }

                // UI khi đăng nhập thành công
                .message {
                    .message__badge {
                        display: flex;
                        .message-icon {
                            width: 28px;
                            height: 28px;
                            line-height: 29px;
                            border-radius: 4px;
                            text-align: center;
                            cursor: pointer;
                            color: #fff;
                            i {
                                font-size: 24px;
                            }
                        }
                    }
                    .user__icon {
                        height: 34px;
                        margin-left: 20px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

// Modal antd
.ant-modal-header {
    text-align: center;
}
.ant-modal-footer {
    button.ant-btn.submit-form {
        opacity: 1;
        color: #fff;
        background-color: var(--text-hover);
        border: 1px solid var(---text-hover);
        font-size: 14px;
        font-weight: 600;
    }
}

.header__scroll {
    background: #302f3d;
    position: fixed;
    width: 100%;
    nav {
        .container {
            #basic-navbar-nav {
                .button__login {
                    background: var(--color-button);
                    border: none;
                    &:hover {
                        background: var(--button-hover);
                    }
                }
                .button__signup {
                    background: var(--text-hover);
                    &:hover {
                        background: #8d65ea;
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    header {
        font-size: 14px;
        background: #302f3d;
        nav {
            .container {
                #basic-navbar-nav {
                    .button__login,
                    .button__signup {
                        font-size: 14px;
                    }
                    .nav__item {
                        &:hover {
                            color: var(--text-hover);
                            &::after {
                                width: 0;
                                opacity: 0;
                            }
                        }
                        &--active {
                            color: var(--text-hover);
                            position: relative;
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
                .me-auto {
                    display: block;
                }
            }
        }
        .navbar-light .navbar-toggler-icon {
            background-image: url("../../assets/Daco_3884885.png") !important;
        }
    }
}

@media only screen and (max-width: 768px) {
    header {
        nav {
            .container {
                #basic-navbar-nav {
                    .user__mobile {
                        display: block;
                    }
                    .message {
                        .user__icon {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 575px) {
    header {
        font-size: 12px;
        nav {
            .container {
                .header__nav img {
                    width: 120px;
                    height: 40px;
                }
                #basic-navbar-nav {
                    .button__login,
                    .button__signup {
                        font-size: 12px;
                    }
                }
                .navbar-toggler {
                    width: 40px;
                    height: 40px;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                }
            }
        }
        .navbar-light .navbar-toggler-icon {
            background-image: url("../../assets/Daco_3884885.png") !important;
        }
    }
}
