#notfound {
    background: #fff;
    position: relative;
    height: 100vh;
    .notfound {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        max-width: 410px;
        width: 100%;
        text-align: center;
        .notfound-404 {
            height: 280px;
            position: relative;
            z-index: -1;
            h1 {
                font-size: 230px;
                margin: 0px;
                font-weight: 900;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                background: url(../../assets/background.jpg) no-repeat;
                background-position: center;
                background-size: cover;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            h2 {
                color: #000;
                font-size: 24px;
                font-weight: 700;
                text-transform: uppercase;
                margin-top: 0;
            }
        }
        a {
            font-size: 14px;
            text-decoration: none;
            text-transform: uppercase;
            background: #a12abd;
            display: inline-block;
            padding: 15px 30px;
            border-radius: 40px;
            color: #fff !important;
            font-weight: 700;
            -webkit-box-shadow: 0px 4px 15px -5px #a12abd;
            box-shadow: 0px 4px 15px -5px #a12abd;
        }
    }
}

@media only screen and (max-width: 767px) {
    #notfound {
        .notfound {
            .notfound-404 {
                height: 142px;
                h1 {
                    font-size: 112px;
                }
            }
        }
    }
}
