.chat-rom {
    margin-top: 90px;
    background: #fff;
    height: 100vh;
    width: 100%;
    display: grid;
    place-items: center;
    .joinChatContainer {
        display: flex;
        flex-direction: column;
        text-align: center;
        h3 {
            font-size: 40px;
            margin-bottom: 1rem;
            text-align: center;
            font-weight: 800;
            text-transform: uppercase;
        }
        input {
            width: 225px;
            height: 40px;
            margin: 7px;
            border-radius: 5px;
            padding: 5px;
            font-size: 16px;
            border: 2px solid var(--text-hover);
            
        }
        button {
            width: 225px;
            height: 50px;
            margin: 7px;
            border: none;
            border-radius: 5px;
            padding: 5px;
            font-size: 16px;
            background: linear-gradient(90deg, rgba(146,83,171,1) 13%, rgba(181,94,218,1) 87%);
            color: #fff;
            cursor: pointer;
            &:hover {
                background: var(--text-hover);
            }
        }
    }
}
.chat-success {
    margin-top: 90px;
    background: #fff;
    height: 100vh;
    width: 100%;
}
